<div
  class="container content-space-2 content-space-t-xl-2 content-space-b-lg-2"
>
  <!-- Heading -->
  <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
    <h2 class="h1">Most Popular Data and API</h2>
    <p>
      Explore our most sought-after datasets and APIs, acclaimed for quality and
      innovation by industry leaders.
    </p>
  </div>
  <!-- End Heading -->
  <div class="row mb-5 mb-md-0">
    <div class="col-sm-6 col-lg-4 mb-4 mb-lg-0" *ngFor="let item of apiList">
      <!-- Card -->
      <div class="card card-sm h-100">
        <div class="p-2">
          <img
            class="card-img"
            [src]="item.apiCoverImage | cfcdn : 'middle'"
            alt="Image Description"
          />
        </div>

        <div class="card-body">
          <h4 class="card-title">
            <a routerLink="/details/{{ item.apiIdentityName }}">{{
              item.apiName
            }}</a>
          </h4>
          <p class="card-text">
            {{ item.apiDescription }}
          </p>

          <!-- List Pointer -->
          <ul class="list-pointer mb-0" *ngFor="let item of item.apiTagses">
            <li class="list-pointer-item">{{ item.apiTagName }}</li>
          </ul>
          <!-- End List Pointer -->
        </div>

        <a
          class="card-footer card-link border-top"
          routerLink="/details/{{ item.apiIdentityName }}"
          >View <i class="bi-chevron-right small ms-1"></i
        ></a>
      </div>
      <!-- End Card -->
    </div>
    <!-- End Col -->
    <!-- End Col -->
  </div>
  <!-- End Row -->
</div>
